import { POLYGON_ADDRESSES } from "./addresses";
import {
  getERC20,
  useERC20,
  useOburnExchange,
  getOburnExchange,
  useOburnTokenPresale,
  getOburnTokenPresale
} from "./contracts";
import { useCalls, useContractFunction, useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";
import { useState } from "react";
import { PromiseOrValue } from "./__generated__/common";

export const useApprove = (tokenAddress: string) => {
  const erc20 = useERC20(tokenAddress);
  const {
    state: approveState,
    send: approve,
    resetState: resetApprove,
  } = useContractFunction(erc20, "approve");
  return { approveState, approve, resetApprove };
};

export const useOBurnExchangeSwapTBurn = () => {
  const OburnExchange = useOburnExchange();
  const {
    state: exchangeState,
    send: exchange,
    resetState: resetExchange,
  } = useContractFunction(OburnExchange, "OBURNExchange");
  return { exchangeState, exchange, resetExchange };
};

export const useOBurnPresaleBuy = () => {
  const OburnPresale = useOburnTokenPresale();
  const {
    state: buyState,
    send: buy,
    resetState: resetBuy,
  } = useContractFunction(OburnPresale, "buyTokens");

  return { buyState, buy, resetBuy };
};

export const useOBurnPresaleCheckWhitelist = () => {
  const OburnPresale = useOburnTokenPresale();

  const [isWhitelisted, setCheckState] = useState<boolean>(false);

  const checkWhitelist = async () => {
    try {
      OburnPresale.singleAddressCheckWhitelist().then((val) => {
        setCheckState(val);
      });
    } catch {
      setCheckState(false);
    }
  };

  return { isWhitelisted, checkWhitelist };
};

/*
export function useBalance(tokenAddress: string): BigNumber {
  const { account, library } = useEthers();

  const [result] = useCalls([
    account && {
      contract: getERC20(
        tokenAddress,
        (library as JsonRpcProvider)?.getSigner()
      ),
      method: "balanceOf",
      args: [account],
    },
  ]);

  return result?.value ? result?.value[0] : BigNumber.from(0);
}
*/

export async function getBalance(tokenAddress: string, account: string, library: any): Promise<BigNumber> {
  const erc20 = getERC20(
    tokenAddress,
    (library as JsonRpcProvider)?.getSigner()
  );

  return await erc20.balanceOf(account as PromiseOrValue<string>);
}

export async function getWhitelisted(presaleAddress: string, library: any): Promise<boolean> {
  const oburnPresale = getOburnTokenPresale(
    presaleAddress,
    (library as JsonRpcProvider)?.getSigner()
  );

  return await oburnPresale.singleAddressCheckWhitelist();
}

export async function getWhitelistSaleActive(presaleAddress: string): Promise<boolean> {
  const oburnPresale = getOburnTokenPresale(presaleAddress);
  return await oburnPresale.getWhitelistSaleActive();
}

export async function getPublicSaleActive(presaleAddress: string): Promise<boolean> {
  const oburnPresale = getOburnTokenPresale(presaleAddress);
  return await oburnPresale.getPublicSaleActive();
}

export async function getExchangedPaused(exchangeAddress: string): Promise<boolean> {
  const oburnExchange = getOburnExchange(exchangeAddress);
  return await oburnExchange.paused();
}
 
export async function getTokenAllowance(tokenAddress: string, spender: string, account: string) : Promise<BigNumber> {
  const erc20 = getERC20(tokenAddress);
  return await erc20.allowance(account, spender);
}

export function useBalanceOf(
  tokenAddress: string,
  holderAddress: string
): BigNumber {
  const { account, library } = useEthers();

  const [result] = useCalls([
    account && {
      contract: getERC20(
        tokenAddress,
        (library as JsonRpcProvider)?.getSigner()
      ),
      method: "balanceOf",
      args: [holderAddress],
    },
  ]);

  return result?.value ? result?.value[0] : BigNumber.from(0);
}
