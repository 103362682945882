import { TextField, Box, CircularProgress, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import styled from "styled-components";
import { useState, useMemo, useEffect } from "react";
import { POLYGON_ADDRESSES } from "../../contracts/addresses";
import {
  useApprove,
  getBalance,
  getWhitelisted,
  useOBurnPresaleBuy,
  getWhitelistSaleActive,
  getPublicSaleActive,
  getTokenAllowance
} from "../../contracts/functions";
import { BigNumber } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import { configs } from "../../config";

const StyledButton = styled.button`
  height: 24px;
  color: white;
  background-color: red;
  display: flex;
  margin: 20px 0 0 30px;
  cursor: pointer;
  :disabled {
    cursor: unset;
    background-color: gray;
  }
`;
const StyledTextField = styled(TextField)`
  margin: 10px;
  root: {
    & .muiinputbase-root.mui-disabled: {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  & label.Mui-focused {
    color: #d97d54;
  }
  & .MuiOutlinedInput-root {
    background: rgba(35, 35, 35, 0.5);
    &.Mui-focused fieldset {
      border-color: #d97d54;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledBox = styled(Box)`
  margin-top: 20px;
  justify-content: center;
`;

const StyledInlineGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const networkData = [
  {
    chainId: "0x89",  // Polygon is chain ID 137 which is 0x89 in hex
    chainName: "POLYGON",
    rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
];

const polygonChainId = 137;

const Whitelist = () => {
  const { account, library, chainId } = useEthers();

  const { buy, buyState } = useOBurnPresaleBuy();
  const { approve, approveState } = useApprove(POLYGON_ADDRESSES.USDC_ERC20);

  const [inputValue, setInputValue] = useState(0);
  const [outputValue, setOutputValue] = useState(0);
  const [usdcBalance, setUSDCBalance] = useState(BigNumber.from(0));
  const [usdcAllowance, setUSDCAllowance] = useState(0.0);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isWhitelistSaleActive, setIsWhitelistSaleActive] = useState(false);
  const [isPublicSaleActive, setIsPublicSaleActive] = useState(false);
  const [approvingUSDC, setApprovingUSDC] = useState(false);
  const [buyingOBURN, setBuyingOBURN] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [showSubmissionPending, setShowSubmissionPending] = useState(false);
  const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false);
  const [showSubmissionFailure, setShowSubmissionFailure] = useState(false);

  if (account && chainId != polygonChainId) {
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: networkData,
      });
  }  

  async function getContractValues() {
    const whiteListSaleActive = await getWhitelistSaleActive(POLYGON_ADDRESSES.OBURN_TOKEN_PRESALE);
    const publicSaleActive = await getPublicSaleActive(POLYGON_ADDRESSES.OBURN_TOKEN_PRESALE);

    setIsWhitelistSaleActive(whiteListSaleActive);
    setIsPublicSaleActive(publicSaleActive);
  }

  async function getUserValues() {
    if (account && library) {
      const currUSDCBalance = await getBalance(POLYGON_ADDRESSES.USDC_ERC20, account, library);
      const whiteListed = await getWhitelisted(POLYGON_ADDRESSES.OBURN_TOKEN_PRESALE, library);
      const currUSDCAllowance = await getTokenAllowance(POLYGON_ADDRESSES.USDC_ERC20, POLYGON_ADDRESSES.OBURN_TOKEN_PRESALE, account);

      setUSDCBalance(currUSDCBalance);
      setIsWhitelisted(whiteListed);
      setUSDCAllowance(parseFloat(formatUnits(currUSDCAllowance ?? 0, 6)));
    }
  }

  useEffect(() => {
    getUserValues();
  }, [account, library])

  useEffect(() => {
    getContractValues();
  }, [])

  useEffect(() => {
    console.log(approveState);
    if (approveState.status === "Success") {
      setShowSubmissionSuccess(true);
      setShowSubmissionPending(false);
      setShowSubmissionFailure(false);
      setApprovingUSDC(false);
      setSubmissionMessage("USDC approved successfully! Click the \"Swap\" button to finalize your OBURN purchase!");
      setTransactionHash(approveState.transaction ? approveState.transaction.hash : "");
      getUserValues();
    }
    else if (approveState.status === "Exception") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(false);
      setShowSubmissionFailure(true);
      setApprovingUSDC(false);
      setSubmissionMessage(`Failed to approve USDC: ${approveState.errorMessage}`);
    }
    else if (approveState.status === "Mining") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(true);
      setShowSubmissionFailure(false);
      setApprovingUSDC(true);
      setSubmissionMessage("Approving USDC...");
      setTransactionHash(approveState.transaction ? approveState.transaction.hash : "");
    }
    else if (approveState.status === "PendingSignature") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(true);
      setShowSubmissionFailure(false);
      setApprovingUSDC(true);
      setSubmissionMessage("Waiting for User to Sign Transaction...");
      setTransactionHash("");
    }
  }, [approveState])  

  useEffect(() => {
    console.log(buyState);
    if (buyState.status === "Success") {
      setShowSubmissionSuccess(true);
      setShowSubmissionPending(false);
      setShowSubmissionFailure(false);
      setBuyingOBURN(false);
      setSubmissionMessage("OBURN purchased successfully!");
      setTransactionHash(buyState.transaction ? buyState.transaction.hash : "");
      getUserValues();
    }
    else if (buyState.status === "Exception") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(false);
      setShowSubmissionFailure(true);
      setBuyingOBURN(false);
      setSubmissionMessage(`Failed to purchase OBURN: ${buyState.errorMessage}`);
    }
    else if (buyState.status === "Mining") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(true);
      setShowSubmissionFailure(false);
      setBuyingOBURN(true);
      setSubmissionMessage("Purchasing OBURN...");
      setTransactionHash(buyState.transaction ? buyState.transaction.hash : "");
    }
    else if (buyState.status === "PendingSignature") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(true);
      setShowSubmissionFailure(false);
      setBuyingOBURN(true);
      setSubmissionMessage("Waiting for User to Sign Transaction...");
      setTransactionHash("");
    }
  }, [buyState])  

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const x = parseFloat(event.target.value);
    const maxValue = 1000;
    const final = x > maxValue ? maxValue : x;
    setInputValue(final);
    const price = isWhitelisted && isWhitelistSaleActive ? 0.000002 : 0.000004;
    setOutputValue(parseFloat((final / price).toFixed(2)));
  };

  const usdcBalanceDecimal = useMemo(() => {
    const x = parseFloat(formatUnits(usdcBalance ?? 0, 6));
    const maxValue = 1000;
    const final = x > maxValue ? maxValue : x;
    return final;
  }, [usdcBalance, isWhitelisted]);

  const setMaxUSDC = () => {
    const maxValue =  1000;
    const final = usdcBalanceDecimal > maxValue ? maxValue : usdcBalanceDecimal;
    setInputValue(final);
    const price = isWhitelisted && isWhitelistSaleActive ? 0.000002 : 0.000004;
    setOutputValue(parseFloat((final / price).toFixed(2)));
  };  

  return (
    <div>
      <Snackbar open={showSubmissionSuccess} autoHideDuration={6000} onClose={() => {setShowSubmissionSuccess(false)}}>
        <MuiAlert elevation={6} variant="filled" onClose={() => {setShowSubmissionSuccess(false)}} severity="success" sx={{ width: '100%' }} className="mobileSnackbarTextSize" >
          {submissionMessage} Transaction hash: <a style={{ color: "white" }} href={`https://polygonscan.com/tx/${transactionHash}`} target="_blank" rel="noreferrer" >{transactionHash}</a>
        </MuiAlert>
      </Snackbar>
      <Snackbar open={showSubmissionFailure} autoHideDuration={6000} onClose={() => {setShowSubmissionFailure(false)}}>
        <MuiAlert elevation={6} variant="filled" onClose={() => {setShowSubmissionFailure(false)}} severity="error" sx={{ width: '100%' }} className="mobileSnackbarTextSize" >
          {submissionMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar open={showSubmissionPending} autoHideDuration={20000} onClose={() => {setShowSubmissionPending(false)}}>
        <MuiAlert elevation={6} variant="filled" onClose={() => {setShowSubmissionPending(false)}} severity="info" sx={{ width: '100%' }} className="mobileSnackbarTextSize" >
          {submissionMessage} {transactionHash && (<b>Transaction hash: <a style={{ color: "white" }} href={`https://polygonscan.com/tx/${transactionHash}`} target="_blank" rel="noreferrer" >{transactionHash}</a></b>)}
        </MuiAlert>
      </Snackbar>

      <p>Whitelist purchase cap per wallet: $500</p>
      <p>Public presale cap per wallet: $1,000</p>
      {isWhitelisted && <p>Congratulations, your wallet is whitelisted!</p>}
      <StyledBox>
        <h3>Exchange USDC for OBurn</h3>
        <StyledInlineGroup>
          <StyledTextField
            value={inputValue}
            type="number"
            onChange={handleInputChange}
            label="USDC Amount"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <button onClick={() => setMaxUSDC()}>
                  Max
                </button>
              ),
              style: { fontFamily: "Arial", color: "white" },
            }}
          ></StyledTextField>
          <StyledTextField
            disabled
            value={outputValue}
            label={"OBurn Amount"}
            inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
            InputLabelProps={{ style: { fontFamily: "Arial", color: "white" } }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "white",
              },
            }}
          />
        </StyledInlineGroup>
        <StyledInlineGroup>
          {
            !isPublicSaleActive && !isWhitelistSaleActive && (
              <StyledButton disabled>
                Presale Not Open
              </StyledButton>              
            )
          }
          {
            isPublicSaleActive && isWhitelistSaleActive && !isWhitelisted && (
              <StyledButton disabled>
                Currently Only for Whitelisted Users
              </StyledButton>              
            )
          }
          {
            (isPublicSaleActive || (isWhitelistSaleActive && isWhitelisted)) && (
              <>
              {
                (inputValue >= 0.00001 || inputValue == 0) && usdcAllowance >= inputValue && (
                  <StyledButton onClick={() => buy(account ?? "", parseUnits(inputValue.toString(), 6))} disabled={inputValue < 0.00001}>
                    {buyingOBURN && <CircularProgress size={18}/>}&nbsp;Swap
                  </StyledButton>                  
                )
              }
              {
                (inputValue >= 0.00001 || inputValue == 0) && usdcAllowance < inputValue && (
                  <StyledButton
                    disabled={!account || inputValue < 0.00001}
                    onClick={() => approve(POLYGON_ADDRESSES.OBURN_TOKEN_PRESALE, parseUnits(inputValue.toString(), 6))}
                  >
                    {approvingUSDC && <CircularProgress size={18}/>}&nbsp;Approve USDC
                  </StyledButton>                  
                )
              }
              {
                inputValue < 0.00001 && inputValue != 0 && (
                  <StyledButton disabled>
                    Need at least 0.00001 USDC
                  </StyledButton>                  
                )
              }
              </>
            )
          }
        </StyledInlineGroup>
      </StyledBox>
    </div>
  );
};

export default Whitelist;
