import { useMemo } from "react";
import { useEthers } from "@usedapp/core";
import { Contract, Signer, ethers } from "ethers";
import { Erc20 } from "./__generated__/Erc20";
import { ERC20Abi, OburnExchangeAbi, OburnTokenPresaleAbi } from "./abis";
import { JsonRpcProvider } from "@ethersproject/providers";
import { POLYGON_ADDRESSES } from "./addresses";
import { OburnExchange, OburnTokenPresale } from "./__generated__";

const provider = new ethers.providers.JsonRpcProvider("https://rpc-mainnet.maticvigil.com");

export function useERC20(address: string) {
  const { library } = useEthers();
  return useMemo(() => {
    return getERC20(address, (library as JsonRpcProvider)?.getSigner());
  }, [address, library]);
}

export function getERC20(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, ERC20Abi, signer) as Erc20;
  }
  else {
    return new Contract(address, ERC20Abi, provider) as Erc20;
  }
}

export function useOburnExchange() {
  const { OBURN_EXCHANGE } = POLYGON_ADDRESSES;
  const { library } = useEthers();
  return new Contract(
    OBURN_EXCHANGE,
    OburnExchangeAbi,
    library
  ) as OburnExchange;
}

export function getOburnExchange(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, OburnExchangeAbi, signer);
  }
  else {
    return new Contract(address, OburnExchangeAbi, provider);
  }
}

export function getOburnTokenPresale(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, OburnTokenPresaleAbi, signer);
  }
  else {
    return new Contract(address, OburnTokenPresaleAbi, provider);
  }
}

export function useOburnTokenPresale() {
  const { OBURN_TOKEN_PRESALE } = POLYGON_ADDRESSES;
  const { library } = useEthers();
  return new Contract(
    OBURN_TOKEN_PRESALE,
    OburnTokenPresaleAbi,
    library
  ) as OburnTokenPresale;
}
