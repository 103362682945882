import { useEffect, useState } from "react";
import styled from "styled-components/macro";

const StyledCountdown = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  gap: 20px;
`;

const StyledNumberSlot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledNumbers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledNumber = styled.span<{ color: string }>`
  width: 28px;
  height: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
`;

function NumberSlot({
  num,
  unit,
  color,
}: {
  num: number;
  unit: string;
  color: string;
}) {
  const firstDigit = Math.floor(num / 10) % 10;
  const secondDigit = num % 10;
  return (
    <StyledNumberSlot>
      <StyledNumbers>
        <StyledNumber color={color}>
          <h1>{firstDigit}</h1>
        </StyledNumber>
        <StyledNumber color={color}>
          <h1>{secondDigit}</h1>
        </StyledNumber>
        {unit !== "Seconds" ? ":" : "‎"}
      </StyledNumbers>
    </StyledNumberSlot>
  );
}

interface Props {
  target: number;
  color: string;
}

export default function Countdown({ target, color }: Props) {
  const [countdown, setCountdown] = useState(Math.max(target - Date.now(), 0));
  const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(Math.max(target.valueOf() - Date.now(), 0));
    }, 1000);
    return () => clearInterval(interval);
  }, [target]);

  return (
    <StyledCountdown>
      <NumberSlot num={days} unit={"Days"} color={color} />
      <NumberSlot num={hours} unit={"Hours"} color={color} />
      <NumberSlot num={minutes} unit={"Minutes"} color={color} />
      <NumberSlot num={seconds} unit={"Seconds"} color={color} />
    </StyledCountdown>
  );
}
