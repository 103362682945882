import { useEthers } from '@usedapp/core'
import { useDispatch, useSelector } from 'react-redux'
import ChooseWallet from './components/ChooseWallet'
import { connectWallet, selectConnectingWallet } from './store'
import Whitelist from './components/Whitelist'
import React from 'react'
import Exchange from './components/Exchange'
import styled from 'styled-components'
import Countdown from './components/Countdown'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { configs } from './config'
import { getBalance } from './contracts/functions'
import { POLYGON_ADDRESSES } from './contracts/addresses'
import { formatUnits } from 'ethers/lib/utils'
import MediaQuery from 'react-responsive'

const BodyBox = styled.div`
  width: 80%;
  height: 460px;
  margin: 10% auto auto auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
`

const MobileBox = styled.div`
  width: 100%;
  height: 800px;
  margin: 2% auto auto auto;
  display: grid;
  justify-content: center;
  align-items: center;
  color: white !important;
`

const StyledRectangle = styled.div<{ bgcolor: string }>`
  background-color: ${({ bgcolor }) => bgcolor};
  width: 95%;
  height: 100%;
  margin: auto;
  border-radius: 8px;
  border: ${({ bgcolor }) => (bgcolor === 'black' ? '1px darkgray solid' : '')};
`

const StyledRectangleMobile = styled.div<{ bgcolor: string }>`
  background-color: ${({ bgcolor }) => bgcolor};
  width: 95%;
  height: 100%;
  margin: auto;
  border-radius: 8px;
  border: ${({ bgcolor }) => (bgcolor === 'black' ? '1px darkgray solid' : '')};
`

const StyledButton = styled.button`
  height: 24px;
  color: white;
  display: flex;
  background-color: red;
  padding: 8px 24px 24px 24px;
  border-radius: 4px;
  margin: 8px;
  float: right;
  font-size: 18px;
  align-items: stretch;
  cursor: pointer;
`

const StyledButtonMobile = styled.button`
  height: 24px;
  color: white;
  display: flex;
  background-color: red;
  padding: 8px 24px 24px 24px;
  border-radius: 4px;
  margin: 8px;
  float: right;
  font-size: 18px;
  align-items: stretch;
  cursor: pointer;
`

const StyledTitle = styled.h1`
  display: flex;
  justify-content: center;
`

const StyledCountdowns = styled.div``

const StyledBox = styled(Box)`
  background: rgb(22, 22, 22);
  width: 60%;
  max-height: 70%;
  margin-left: 20px;
  border-radius: 8px;
  padding: 20px;
`

const StyledTabs = styled(Tabs)`
  justify-content: center;
  display: flex;
`

const StyledTab = styled(Tab)`
  color: white !important;
`

const StyledInlineBoxes = styled.div`
  display: flex;
`

const StyledInlineBoxesMobile = styled.div`
  display: inline-grid;
  width: 100%;
`

const StyledRemaining = styled.div`
  padding-top: 20px;
`

function App() {
  const dispatch = useDispatch()
  const { account } = useEthers()
  const [tab, setTab] = React.useState(0)
  const [oburnRemaining, setOBURNRemaining] = React.useState(0)

  async function getContractValues() {
    const currOBURNRemaining = await getBalance(
      POLYGON_ADDRESSES.OBURN_ERC20,
      POLYGON_ADDRESSES.OBURN_TOKEN_PRESALE,
      undefined,
    )
    setOBURNRemaining(
      parseFloat(
        parseFloat(formatUnits(currOBURNRemaining ?? 0, 18)).toFixed(0),
      ),
    )
  }

  React.useEffect(() => {
    getContractValues()
  }, [])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  return (
    <div>
      <MediaQuery minWidth={1064}>
        <BodyBox>
          <StyledRectangle bgcolor={'orange'}>
            <StyledRectangle bgcolor={'yellow'}>
              <StyledRectangle bgcolor={'red'}>
                <StyledRectangle bgcolor={'black'}>
                  <StyledTitle>OBurn Presale</StyledTitle>

                  <StyledButton onClick={() => dispatch(connectWallet())}>
                    {account
                      ? `${account?.substring(0, 5)}...${account?.slice(-3)}`
                      : 'Connect'}
                  </StyledButton>
                  <ChooseWallet />
                  <StyledInlineBoxes>
                    <StyledBox>
                      <StyledTabs
                        value={tab}
                        onChange={handleTabChange}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: '#D97D54',
                          },
                        }}
                      >
                        <StyledTab label={'Presale'} id={'0'}></StyledTab>
                        <StyledTab label={'Exchange'} id={'1'}></StyledTab>
                      </StyledTabs>
                      {tab === 0 && <Whitelist />}
                      {tab === 1 && <Exchange />}
                    </StyledBox>

                    <StyledBox>
                      <StyledCountdowns>
                        <h2>Time Remaining:</h2>
                        {Date.now() < configs.PUBLIC_UNIXDATE ? (
                          <div>
                            <div>
                              {'Whitelist:'}
                              <Countdown
                                target={configs.WHITELIST_UNIXDATE}
                                color={'yellow'}
                              />
                            </div>
                            <div>
                              {'Public:'}
                              <Countdown
                                target={configs.PUBLIC_UNIXDATE}
                                color={'orange'}
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            {'Presale Ends:'}
                            <Countdown
                              target={configs.END_UNIXDATE}
                              color={'red'}
                            />
                          </div>
                        )}
                      </StyledCountdowns>
                      <StyledRemaining>
                        OBurn Remaining: {oburnRemaining.toLocaleString()}
                      </StyledRemaining>
                    </StyledBox>
                  </StyledInlineBoxes>
                </StyledRectangle>
              </StyledRectangle>
            </StyledRectangle>
          </StyledRectangle>
        </BodyBox>
      </MediaQuery>

      <MediaQuery maxWidth={1063}>
        <MobileBox>
          <StyledRectangleMobile bgcolor={'black'}>
            <StyledTitle>OBurn Presale</StyledTitle>
            <ChooseWallet />
            <StyledButtonMobile onClick={() => dispatch(connectWallet())}>
              {account
                ? `${account?.substring(0, 5)}...${account?.slice(-3)}`
                : 'Connect'}
            </StyledButtonMobile>

            <StyledInlineBoxesMobile>
              
              
                <Whitelist />
                <Exchange />
              
            </StyledInlineBoxesMobile>
          </StyledRectangleMobile>
        </MobileBox>
      </MediaQuery>
    </div>
  )
}
export default App
