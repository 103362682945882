import { useEthers } from "@usedapp/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import CoinbaseWalletIcon from "../../assets/wallets/coinbase.png";
import MetamaskWalletIcon from "../../assets/wallets/metamask.jpg";
import WalletConnectIcon from "../../assets/wallets/walletconnect.jpg";
import Popup from "../Popup";
import { selectConnectingWallet, walletIsConnected } from "../../store";

const CoinbaseWallet = new WalletLinkConnector({
  url: "https://polygon-rpc.com",
  appName: "Ottopia",
  supportedChainIds: [137],
});

export const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    137: "https://polygon-rpc.com",
  },
  qrcode: true,
});

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Option = styled.button`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 10px;

  padding: 0 20px;
`;

const Name = styled.h2`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Icon = styled.img`
  width: 36px;
`;

const ChooseWallet = (): JSX.Element => {
  const { account, activateBrowserWallet, activate } = useEthers();
  const connectingWallet = useSelector(selectConnectingWallet);
  const dispatch = useDispatch();

  return (
    <Popup
      show={connectingWallet && !account}
      close={() => dispatch(walletIsConnected())}
      header={"Select Wallet"}
    >
      <StyledContainer>
        <Option
          onClick={() => activateBrowserWallet()}
          style={{ cursor: "pointer" }}
        >
          <Name>Metamask</Name>
          <Icon src={MetamaskWalletIcon} alt="Metamask logo" />
        </Option>
        <Option
          onClick={() => activate(walletConnectConnector)}
          style={{ cursor: "pointer" }}
        >
          <Name>WalletConnect</Name>
          <Icon src={WalletConnectIcon} alt="WalletConnect logo" />
        </Option>
        <Option
          onClick={() => activate(CoinbaseWallet)}
          style={{ cursor: "pointer" }}
        >
          <Name>Coinbase Wallet</Name>
          <Icon src={CoinbaseWalletIcon} alt="Coinbase Wallet logo" />
        </Option>
      </StyledContainer>
    </Popup>
  );
};

export default ChooseWallet;
