import { TextField, Box, CircularProgress, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useEthers } from "@usedapp/core";
import { POLYGON_ADDRESSES } from "../../contracts/addresses";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { useState, useMemo, useEffect } from "react";
import MediaQuery from 'react-responsive'

import {
  useApprove,
  getBalance,
  getExchangedPaused,
  getTokenAllowance,
  useOBurnExchangeSwapTBurn
} from "../../contracts/functions";
import { BigNumber, ethers } from "ethers";
import styled from "styled-components";

const StyledButton = styled.button`
  height: 24px;
  color: white;
  background-color: red;
  display: flex;
  margin: 20px 0 0 30px;
  cursor: pointer;
  :disabled {
    cursor: unset;
    background-color: gray;
  }
`;
const StyledTextField = styled(TextField)`
  margin: 10px;
  & label.Mui-focused {
    color: #d97d54;
  }
  & .MuiOutlinedInput-root {
    background: rgba(35, 35, 35, 0.5);
    &.Mui-focused fieldset {
      border-color: #d97d54;
    }
  }
  & MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    color: #f0f0f0;
    -webkit-text-fill-color: unset;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledBox = styled(Box)`
  margin-top: 20px;
  justify-content: center;
`;

const StyledInlineGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const networkData = [
  {
    chainId: "0x89",  // Polygon is chain ID 137 which is 0x89 in hex
    chainName: "POLYGON",
    rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
];

const polygonChainId = 137;

const Exchange = () => {
  const { account, library, chainId } = useEthers();
  const { approve, approveState } = useApprove(POLYGON_ADDRESSES.TBURN_ERC20);
  const { exchange, exchangeState } = useOBurnExchangeSwapTBurn();

  const [tburnBalance, setTBURNBalance] = useState(BigNumber.from(0));

  const tBurnBalanceDecimal = useMemo(
    () => parseFloat(formatUnits(tburnBalance ?? 0, 18)),
    [tburnBalance]
  );

  const [inputValue, setInputValue] = useState(tBurnBalanceDecimal);
  const [isExchangePaused, setIsExchangePaused] = useState(false);
  const [tBurnAllowance, setTBURNAllowance] = useState(0.0);
  const [approvingTBURN, setApprovingTBURN] = useState(false);
  const [exchangingTBURN, setExchangingTBURN] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [showSubmissionPending, setShowSubmissionPending] = useState(false);
  const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false);
  const [showSubmissionFailure, setShowSubmissionFailure] = useState(false);

  if (account && chainId != polygonChainId) {
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: networkData,
      });
  } 

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(parseFloat(event.target.value));
  };  

  async function getContractValues() {
    const currIsExchangePaused = await getExchangedPaused(POLYGON_ADDRESSES.OBURN_EXCHANGE);

    setIsExchangePaused(currIsExchangePaused);
  }

  async function getUserValues() {
    if (account && library) {
      const currUSDCBalance = await getBalance(POLYGON_ADDRESSES.TBURN_ERC20, account, library);
      const currTBURNAllowance = await getTokenAllowance(POLYGON_ADDRESSES.TBURN_ERC20, POLYGON_ADDRESSES.OBURN_EXCHANGE, account);

      setTBURNBalance(currUSDCBalance);
      setTBURNAllowance(parseFloat(formatUnits(currTBURNAllowance ?? 0, 18)))
    }
  }

  useEffect(() => {
    getUserValues();
  }, [account, library])

  useEffect(() => {
    getContractValues();
  }, [])

  useEffect(() => {
    console.log(approveState);
    if (approveState.status === "Success") {
      setShowSubmissionSuccess(true);
      setShowSubmissionPending(false);
      setShowSubmissionFailure(false);
      setApprovingTBURN(false);
      setSubmissionMessage("TBURN approved successfully! Click the \"Swap\" button to finalize your swap for OBURN!");
      setTransactionHash(approveState.transaction ? approveState.transaction.hash : "");
      getUserValues();
    }
    else if (approveState.status === "Exception") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(false);
      setShowSubmissionFailure(true);
      setApprovingTBURN(false);
      setSubmissionMessage(`Failed to approve TBURN: ${approveState.errorMessage}`);
    }
    else if (approveState.status === "Mining") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(true);
      setShowSubmissionFailure(false);
      setApprovingTBURN(true);
      setSubmissionMessage("Approving TBURN...");
      setTransactionHash(approveState.transaction ? approveState.transaction.hash : "");
    }
    else if (approveState.status === "PendingSignature") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(true);
      setShowSubmissionFailure(false);
      setApprovingTBURN(true);
      setSubmissionMessage("Waiting for User to Sign Transaction...");
      setTransactionHash("");
    }
  }, [approveState])  

  useEffect(() => {
    console.log(exchangeState);
    if (exchangeState.status === "Success") {
      setShowSubmissionSuccess(true);
      setShowSubmissionPending(false);
      setShowSubmissionFailure(false);
      setExchangingTBURN(false);
      setSubmissionMessage("TBURN exchanged for OBURN successfully!");
      setTransactionHash(exchangeState.transaction ? exchangeState.transaction.hash : "");
      getUserValues();
    }
    else if (exchangeState.status === "Exception") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(false);
      setShowSubmissionFailure(true);
      setExchangingTBURN(false);
      setSubmissionMessage(`Failed to exchange TBURN for OBURN: ${exchangeState.errorMessage}`);
    }
    else if (exchangeState.status === "Mining") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(true);
      setShowSubmissionFailure(false);
      setExchangingTBURN(true);
      setSubmissionMessage("Exchanging TBURN for OBURN...");
      setTransactionHash(exchangeState.transaction ? exchangeState.transaction.hash : "");
    }
    else if (exchangeState.status === "PendingSignature") {
      setShowSubmissionSuccess(false);
      setShowSubmissionPending(true);
      setShowSubmissionFailure(false);
      setExchangingTBURN(true);
      setSubmissionMessage("Waiting for User to Sign Transaction...");
      setTransactionHash("");
    }
  }, [exchangeState])  

  return (
    <StyledBox>
      <Snackbar open={showSubmissionSuccess} autoHideDuration={6000} onClose={() => {setShowSubmissionSuccess(false)}}>
        <MuiAlert elevation={6} variant="filled" onClose={() => {setShowSubmissionSuccess(false)}} severity="success" sx={{ width: '100%' }} className="mobileSnackbarTextSize" >
          {submissionMessage} Transaction hash: <a style={{ color: "white" }} href={`https://polygonscan.com/tx/${transactionHash}`} target="_blank" rel="noreferrer" >{transactionHash}</a>
        </MuiAlert>
      </Snackbar>
      <Snackbar open={showSubmissionFailure} autoHideDuration={6000} onClose={() => {setShowSubmissionFailure(false)}}>
        <MuiAlert elevation={6} variant="filled" onClose={() => {setShowSubmissionFailure(false)}} severity="error" sx={{ width: '100%' }} className="mobileSnackbarTextSize" >
          {submissionMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar open={showSubmissionPending} autoHideDuration={20000} onClose={() => {setShowSubmissionPending(false)}}>
        <MuiAlert elevation={6} variant="filled" onClose={() => {setShowSubmissionPending(false)}} severity="info" sx={{ width: '100%' }} className="mobileSnackbarTextSize" >
          {submissionMessage} {transactionHash && (<b>Transaction hash: <a style={{ color: "white" }} href={`https://polygonscan.com/tx/${transactionHash}`} target="_blank" rel="noreferrer" >{transactionHash}</a></b>)}
        </MuiAlert>
      </Snackbar>

      <h3>Exchange TBurn for OBurn</h3>
      <StyledInlineGroup>
        <StyledTextField
          value={inputValue}
          type="number"
          onChange={handleInputChange}
          label="TBurn Amount"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <button onClick={() => setInputValue(tBurnBalanceDecimal)}>
                Max
              </button>
            ),
            style: { fontFamily: "Arial", color: "white" },
          }}
        ></StyledTextField>
        <StyledTextField
          disabled
          label="OBurn Amount"
          value={inputValue}
          InputLabelProps={{ style: { fontFamily: "Arial", color: "white" } }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "white",
            },
          }}
        />
      </StyledInlineGroup>
      <StyledInlineGroup>
        {
          isExchangePaused && (
            <StyledButton disabled> 
              Exchange is not Active
            </StyledButton>            
          )
        }
        {
          !isExchangePaused && (
            <>
              {
                (inputValue >= 0.00000000000000001 || inputValue == 0) && tBurnAllowance >= inputValue && (
                  <StyledButton onClick={() => exchange(parseUnits(inputValue.toString(), 18))} disabled={inputValue < 0.00000000000000001}>
                    {exchangingTBURN && <CircularProgress size={18}/>}&nbsp;Swap
                  </StyledButton>                      
                )
              }
              {
                (inputValue >= 0.00000000000000001 || inputValue == 0) && tBurnAllowance < inputValue && (
                  <StyledButton
                    disabled={!account || inputValue < 0.00000000000000001}
                    onClick={() => approve(POLYGON_ADDRESSES.OBURN_EXCHANGE, parseUnits(inputValue.toString(), 18))}
                  >
                    {approvingTBURN && <CircularProgress size={18}/>}&nbsp;Approve TBURN
                  </StyledButton>                   
                )
              }
              {
                inputValue < 0.00000000000000001 && inputValue != 0 && (
                  <StyledButton disabled>
                    TBURN Amount too Small
                  </StyledButton>                  
                )
              }              
            </>
          )
        }
      </StyledInlineGroup>
    </StyledBox>
  );
};

export default Exchange;
